import React from 'react'

export default React.createContext({
    users: [],
    venues: [],
    caterers: [],
    photographers: [],
    florists: [],
    guests: [],
    currentUser: {},
    deleteVenue: () => {},
    addVenue: () => {},
    updateVenue: () => {},
    deleteCaterer: () => {},
    addCaterer: () => {},
    updateCaterer: () => {},
    deleteFlorist: () => {},
    addFlorist: () => {},
    updateFlorist: () => {},
    deletePhotographer: () => {},
    addPhotographer: () => {},
    updatePhotographer: () => {},
    deleteGuest: () => {},
    addGuest: () => {},
    updateGuest: () => {},
    addUser: () => {},
    setCurrentUser: () => {},
    setUsers: () => {},
    setVenues: () => {},
    setCaterers: () => {},
    setPhorographers: () => [],
    setFlorists: () => {},
    setGuests: () => {}
})